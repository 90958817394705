<template>
  <div class="login-page">
    <n-loader :loading="$var('load')" />
    <div class="login-wrapper">
      <div class="logo-place">
        <div class="logo">
          <n-icon icon="logo" />
        </div>
      </div>
      <c-select-login-type :selected.sync="loginType" />
      <n-form class="form" @submit="login">
        <div class="inputs">
          <n-input v-if="loginType.value === 'iin'" placeholder="ИИН"
                   :danger="$var('m1')" class="auth-input" v-bind="$form.input('iin')"></n-input>
          <n-input v-else placeholder="Телефон"
                   name="phoneinput" :danger="$var('m1')" class="auth-input" v-bind="$form.input('phone')"></n-input>

          <n-input placeholder="Пароль" type="password" :danger="$var('m2')" class="auth-input"
                   v-bind="$form.input('password')"></n-input>
          <div class="bottom-buttons">
            <div class="forget" @click="$router.push({name: 'register'})">
              Регистрация
            </div>
            <div class="forget" @click="$router.push({name: 'recover'})">
              Забыли пароль?
            </div>
          </div>
        </div>
        <div class="auth-buttons">
          <n-button class="auth-button" type="submit">Войти</n-button>
        </div>
      </n-form>
    </div>
  </div>
</template>

<script>

import { PushNotifications, } from '@capacitor/push-notifications'
import { Capacitor, } from '@capacitor/core'
import { FCM, } from '@capacitor-community/fcm'

export default {
  name: 'PageLogin',
  data() {
    return {
      loginType: { value: 'phone', title: 'Номер телефона', },
    }
  },
  mounted() {
    this.accessInput()
  },
  created() {
    this.$app.auth.needAuth(false)
    this.$form.init({
      iin: '',
      phone: '+7',
      password: '',
    })
    this.$form.masks({
      phone: [ 'phone', ],
    })
    this.$form.rules({
      password: [ 'required', ],
    })
  },
  methods: {
    login() {
      if (this.$form.check()) {
        this.$var('load', true)
        $n.getImei().then((res) => {
          const data = {
            ...this.$form.get(),
            login: this.loginType.value === 'iin' ? this.$form.get('iin') : this.$form.get('phone'),
            imei: res,
          }
          if ($n.notifyAvailable()) {
            $api.auth.login(data).then((response) => {
              const loginData = response.data.content
              localStorage.setItem('color', loginData.user.company.color)
              $app.auth.login(loginData.user, loginData.token, loginData.grants)
              PushNotifications.register()
              PushNotifications.addListener('registration', (token) => {
                const platform = Capacitor.getPlatform()
                let fcmToken = ''
                if (platform === 'ios') {
                  FCM.getToken().then((result) => {
                    fcmToken = result.token
                    $api.fcmPush.save({ fcmToken: fcmToken, }).then(() => {
                      localStorage.setItem('fcmToken', fcmToken)
                      PushNotifications.removeAllListeners()
                      this.$var('load', false)
                    })
                  })
                } else if (platform === 'android') {
                  fcmToken = token.value
                  $api.fcmPush.save({ fcmToken: fcmToken, }).then(() => {
                    localStorage.setItem('fcmToken', fcmToken)
                    PushNotifications.removeAllListeners()
                    this.$var('load', false)
                  })
                }
              })
            }).catch((error) => {
              const messages = error.response.data.data
              this.$var('m1', messages.login)
              this.$var('m2', messages.password)
              this.$var('load', false)
            })
          } else {
            $api.auth.login(data).then((response) => {
              const loginData = response.data.content
              localStorage.setItem('color', loginData.user.company.color)
              $app.auth.login(loginData.user, loginData.token, loginData.grants)
            }).catch((error) => {
              const messages = error.response.data.data
              this.$var('m1', messages.login)
              this.$var('m2', messages.password)
            }).finally(() => {
              this.$var('load', false)
            })
          }
        })
      } else {
        if (!this.$form.get('login')) {
          this.$var('m1', 'Поле обязательно для заполнения')
        }
        if (!this.$form.get('password')) {
          this.$var('m2', 'Поле обязательно для заполнения')
        }
      }
    },
    accessInput() {
      const inputElement = document.getElementById('phoneinput')
      inputElement.maxLength = 18
    },
  },
}
</script>

<style lang="scss" scoped>
.login-page {
  .login-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .bottom-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .logo-place {
    margin-bottom: 60px;
  }

  .form {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .forget {
    color: var(--secondary);
    text-align: center;
    text-decoration: underline;
  }

  .auth-buttons {
    margin-top: 16vh;
    display: flex;
    align-items: center;
    flex-direction: column;

    ::v-deep .n-button {
      margin-bottom: 20px;
      background-color: var(--primary);
      width: 85vw;
      height: 40px;
      border-radius: 32px;

      .content {
        color: #FFFFFF;
        font-size: 1em;
      }
    }
  }

  .auth-input {
    margin-bottom: 20px;
  }
}
</style>
